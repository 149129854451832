@tailwind base;
@tailwind components;
@tailwind utilities;

// lato font
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
*{
    font-family: 'Lato', sans-serif;
    @apply text-[1.25rem] text-[#3E3E3E];
}


@layer utilities{
   


    .navHover{
        background: linear-gradient(0deg,#01ABAB 50%, #01ABAB 20%,#0097CE 20%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.banner1{
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/home.png);
    background-size: cover;
    height: 47rem;
    width: 100%;
}

.bannerPadding-px{
    @apply lg:px-16 md:px-12 px-8;
}
.bannerPadding-py{
    @apply  lg:py-16 md:py-12 py-8;
}

.bor{
    @apply border border-red-500
}
.p1{
    right: -58%;
    top: -30%;
}
.p2{
    right: -25%;
    top: -60%;

}
.banner4{
    @apply bg-[#1B1D21];
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
}
.banner5{
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/Mask\ group.png);
    background-size: cover;
    position: relative;
    &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: #048DDC;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}
.banner6{
 
    position: relative;
    z-index: 1;
    
    &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../images/sarvice.png);
        background-size: cover;
        height: 47rem;
        width: 100%;
        z-index: 0;
    }
}
.banner9{
 
    position: relative;
    z-index: 1;
    
    &::before{
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../images/contact.png);
        background-size: cover;
        height: 47rem;
        width: 100%;
        z-index: 0;
    }
}
.banner8{
    background-position: center center;
        background-repeat: no-repeat;
        background-image: url(../images/sarvice2.png);
        background-size: cover;
}

.smallLogo{
    // background: linear-gradient(#0097CE,#01ABAB,#01C47E);
    background: linear-gradient(25deg,#01ABAB  ,#0097CE );
}

.navlinks{
    // background: linear-gradient(25deg,#01ABAB  ,#0097CE );
    // background: RED;
    background-color: white;
   
}